import React from 'react';
import {Link} from 'react-scroll';
import logo from '../images/header-poster-web.jpg'

function Navbar() {
  return (
    <div>
        <nav>
           {/* 
            <Link to='main' className='logo'>
                <img src={logo} alt='logo'/>
            </Link>
            */}
            <input className='menu-btn' type='checkbox' id='menu-btn'/>
            <label className='menu-icon' for='menu-btn'>
                <span className='nav-icon'></span>
            </label>
            <ul className='menu'>
                <li><Link to='services' >Hadiah</Link></li>
                <li><Link to='caragabung' >Cara Bergabung</Link></li>
                <li><Link to='daftar' >Daftar</Link></li>
                <li><Link to='syarat' >Syarat & Ketentuan</Link></li>
            </ul>
            
        </nav>
    </div>
  )
}

export default Navbar;