import React from 'react';
import Header from './components/Header';
import Hadiah from './components/Hadiah';
import CaraBergabung from './components/CaraBergabung';
import SyaratKetentuan from './components/SyaratKetentuan';

function App() {
  return (
    <div className="App">
    <Header/>
    <Hadiah/>
    <CaraBergabung/>
    <SyaratKetentuan/>
    </div>
  );
}

export default App;
