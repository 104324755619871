import React from 'react'
import hadiahimage from '../images/new york time square.png'
import hadiahlogo from '../images/hadiah.png'

function Hadiah() {
  return (
    <div id='services'>
        <div className='s-heading'>
        <h1>HADIAH</h1>
        </div>
        <div id='hadiah'>
            <div className='hadiah-model'>
                <img src={hadiahimage} alt='hadiah-image' width="100%" ></img>
            </div>
            <div className='hadiah-text'>
                <h2> Dua pemenang akan mendapatkan paket perjalanan ke New York, Amerika Serikat selama 5 hari untuk menonton pertandingan UFC langsung di Madison Square Garden. 
                  Ikuti 2 langkah mudah di bawah ini untuk bergabung!
                </h2>
            </div>
        </div>
    </div>
  )
}

export default Hadiah