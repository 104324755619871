import React from 'react';
import Navbar from './Navbar';
import header from '../images/polytron-x-mola-new-york---Winner---Desktop-1920x997.jpg'
import mobileheader from '../images/polytron-x-mola-new-york---Winner---Mobile-720-x-1200.jpg'


function Header() {
  return (
    <div id='main'>
        <div id='main'>
         <img className='img-desktop' src={header} width="100%" alt='header'>
         </img>
         <img className='img-mobile' src={mobileheader} width="100%" alt='header'>
         </img>
        </div>
        <div> 
        <Navbar/>        
        </div>
    </div>
   
  )
}

export default Header;